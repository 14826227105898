import React, { useContext, useState, useEffect, createContext } from "react";

import { useAuth } from "./auth";
import { getUsers } from "../helpers/supabase";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [users, setUsers] = useState();
  const [usersLoading, setUsersLoading] = useState(true);
  const { admin } = useAuth();

  const getUsersFromSupabase = async () => {
    if (typeof users === "undefined" && admin) {
      getUsers().then((response) => {
        setUsers(response);
        setUsersLoading(false);
      });
    }
  };

  useEffect(() => {
    getUsersFromSupabase();
  });

  return (
    <AdminContext.Provider
      value={{
        users,
        setUsers,
        usersLoading,
        setUsersLoading,
        getUsersFromSupabase,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  return useContext(AdminContext);
};
