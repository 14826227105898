import React from "react";

import "../styles/styles.scss";

import Layout from "../components/layout";
import Resources from "../components/resources";

const ResourcesPage = () => {

  return (
    <Layout pageName="Resources">
      <p class="title">Resources</p>
      <Resources />
    </Layout>
  );
};

export default ResourcesPage;
