import React from "react";
import Helmet from "react-helmet";

import "../styles/styles.scss";
import Navbar from "./navbar";
import { AuthProvider, useAuth } from "../context/auth";
import { AdminProvider } from "../context/adminContext";
import { navigate } from "gatsby";
import Feedback from "./Feedback";

const LayoutBase = ({ pageName, children }) => {
  const { user } = useAuth();
  if (!user) {
    navigate("/login");
    return <div />;
  } else {
    return (
      <div>
        <Helmet title={`${pageName} | Frontier Solutions`} />
        <Navbar />
        <Feedback />
        <div class="section is-medium fpAuthenticatedContent">{children}</div>
      </div>
    );
  }
};

const Layout = ({ pageName, children }) => {
  return (
    <AuthProvider>
      <AdminProvider>
        <LayoutBase pageName={pageName}>{children}</LayoutBase>
      </AdminProvider>
    </AuthProvider>
  );
};

export default Layout;
