import React from "react";
import { Link, navigate } from "gatsby";

import { useAuth } from "../context/auth";
import logo from "../images/frontier_logo.png";
import { currentPage, isOnAdminPage } from "../helpers/globalHelpers";
import { logRepActivity, sleep } from "../helpers/supabase";

const toggleMenuActive = () => {
  // For mobile menu, call this when you want to change the state of the menu
  document.querySelector(".navbar-menu").classList.toggle("is-active");
  document.querySelector(".navbar-burger").classList.toggle("is-active");
};

const AdminNavbarStart = () => (
  <div class="navbar-start">
    <div class="navbar-item has-dropdown is-hoverable">
      <a
        class="navbar-item"
        style={{
          fontWeight: "normal",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        Forms
      </a>
      <div class="navbar-dropdown">
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=complianceForms")}
        >
          Compliance Forms
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=customerInformationForms")}
        >
          CIFs
        </a>
      </div>
    </div>
    <div class="navbar-item has-dropdown is-hoverable">
      <a
        class="navbar-item"
        style={{
          fontWeight: "normal",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        Tables
      </a>
      <div class="navbar-dropdown">
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=userData")}
        >
          Reps
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=engagements2")}
        >
          Engagements
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=investors")}
        >
          Investors
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=pst")}
        >
          PST
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=oba")}
        >
          OBA
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=outside-brokerage-account")}
        >
          Outside Brokerage Accounts
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=political-contributions")}
        >
          Political Contributions & Gifts
        </a>
        <a
          class="navbar-item"
          style={{
            fontWeight: "normal",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
          onClick={() => navigate("/admin?section=cyber-security")}
        >
          Cyber Security
        </a>
      </div>
    </div>
    <a
      class="navbar-item"
      style={{
        fontWeight: "normal",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      }}
      onClick={() => navigate("/admin?section=userManagement")}
    >
      Access
    </a>
  </div>
);
const Navbar = () => {
  const { admin, dba, signOut, user } = useAuth();
  logRepActivity(user.email, "pageVisit", currentPage()).then((res) => {});

  return (
    <nav
      class="navbar is-fixed-top box fpNavbar fpNoPrint"
      role="navigation"
      area="main-navigation"
    >
      <div class="navbar-brand">
        <img
          src={logo}
          height={30}
          width={200}
          onClick={() => navigate("/")}
          class="fpLogo"
        />
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="true"
          data-target="fpNavbar"
          onClick={toggleMenuActive}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div id="fpNavbar" class="navbar-menu">
        {user && dba && isOnAdminPage() && <AdminNavbarStart />}
        {user && dba && !isOnAdminPage() && (
          <div class="navbar-start">
            <Link
              class="navbar-item"
              style={{
                fontWeight: "normal",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              to="/selectForm?formType=complianceForms"
              onClick={() => toggleMenuActive()}
            >
              Compliance Forms
            </Link>
            <Link
              class="navbar-item"
              style={{
                fontWeight: "normal",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              onClick={() => toggleMenuActive()}
              to="/selectForm?formType=customerInformationForms"
            >
              Customer Information Forms
            </Link>
            {/* For some reason, <Link /> formats incorrectly in prod */}
            <a
              class="navbar-item"
              style={{
                fontWeight: "normal",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              onClick={() => {
                toggleMenuActive();
                navigate("/resources");
              }}
            >
              Resources
            </a>
            <a
              class="navbar-item"
              style={{
                fontWeight: "normal",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              href="https://forms.deliveryslip.com/frontiersolutionsllc/dvick/"
            >
              Submit Documents
            </a>
            <a
              class="navbar-item"
              style={{
                fontWeight: "normal",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              href="/tables"
            >
              Tables
            </a>
          </div>
        )}
        <div class="navbar-end">
          <div class="navbar-item">
            <p class="is-size-7">
              <b>{user.email}</b>
              <br />({dba ? dba : "not yet associated"})
            </p>
          </div>
          {admin && (
            <div class="navbar-item">
              <Link
                class=" button is-small fpLoginButton"
                to={isOnAdminPage() ? "/" : "/admin"}
                style={{ fontWeight: "normal" }}
              >
                {isOnAdminPage() ? "User" : "Admin"}
              </Link>
            </div>
          )}
          <div class="navbar-item">
            <a
              class="button is-small"
              onClick={async () => {
                navigate("/login");
                await sleep(100);
                signOut();
              }}
              style={{ fontWeight: "normal" }}
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
