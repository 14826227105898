import React from "react";
import { downloadFileFromStorage, logRepActivity } from "../helpers/supabase";

import { AiOutlineCloudDownload } from "react-icons/ai";

const Resource = (props) => {
  const updatedAt = new Date(props.updatedAt);
  const updatedAtLocal = `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}`;

  return (
    <tr>
      <td>
        <p class="subtitle has-text-centered">
          <AiOutlineCloudDownload
            style={{ cursor: "pointer" }}
            onClick={() => {
              downloadFileFromStorage("resources", props.folder, props.name);
              logRepActivity(props.user.email, "downloadResource", props.name).then((res) => {});;
            }}
          />
        </p>
      </td>
      <td class="fpResourceNameColumn">{props.name}</td>
      <td>{updatedAtLocal}</td>
    </tr>
  );
};

export default Resource;
