import React, { useState } from "react";

import { AiOutlineComment } from "react-icons/ai";

import { useForm } from "react-hook-form";
import { useAuth } from "../context/auth";
import { supabase } from "../helpers/supabase";
import { RingLoader } from "react-spinners";

const Feedback = () => {
  const { user } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const submitFeedback = (data) => {
    setIsSubmitting(true);
    supabase
      .from("feedback")
      .insert({
        email: user.email,
        feedback: data.feedback,
        submission_time: new Date().toISOString(),
      })
      .then((response) => {
        setIsSubmitting(false);
        if (response.error) {
          alert(`ERROR: ${response.error.message}`);
        } else {
          alert(`Thanks for your feedback!`);
          document.getElementById("feedbackForm").classList.add("is-hidden");
          reset();
        }
      });
  };

  return (
    <div class="fpNoPrint">
      <button
        class="button is-small fpLoginButton fpFeedbackButton"
        onClick={() =>
          document.getElementById("feedbackForm").classList.toggle("is-hidden")
        }
      >
        <span class="icon">
          <AiOutlineComment />
        </span>
        <span>Feedback?</span>
      </button>
      <form
        id="feedbackForm"
        class="box fpFeedbackForm is-hidden"
        onSubmit={handleSubmit(submitFeedback)}
      >
        <div class="field">
          <textarea
            {...register("feedback", { required: true })}
            class="textarea"
            placeholder="We built this portal for you. Please let us know how we can make your life easier!"
          />
        </div>
        <div class="columns is-mobile">
          <div class="column">
            <button class="button is-small fpLoginButton is-fullwidth">
              {isSubmitting ? <RingLoader size={24} /> : "Submit"}
            </button>
          </div>
          <div class="column">
            <button
              class="button is-small is-fullwidth"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("feedbackForm")
                  .classList.add("is-hidden");
                reset();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <p class="is-size-7">
          Have a technical issue? Please use our on-page chat or email{" "}
          <a href="mailto:dvick@frontiersolutionsllc.com">Davis Vick</a>
        </p>
      </form>
    </div>
  );
};

export default Feedback;
