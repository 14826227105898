import React, { useEffect, useState } from "react";
import { getResourceFilesByFolder } from "../helpers/supabase";
import { RingLoader } from "react-spinners";
import { useAuth } from "../context/auth";

import Resource from "./resource";

const Resources = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState();

  useEffect(() => {
    if (typeof resources === "undefined") {
      getResourceFilesByFolder().then((response) => {
        setResources(response);
        setLoading(false);
      });
    }
  });

  const resourcesToArray = (resources) => {
    return Object.keys(resources).map((folder) => (
      <div>
        <p class="subtitle has-text-weight-bold fpResourceFolder">{folder}</p>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th>File Name</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          {resources[folder].map((file) => {
            if (file.name !== ".emptyFolderPlaceholder") {
              return (
                <Resource
                  user={user}
                  folder={folder}
                  name={file.name}
                  updatedAt={file.updated_at}
                />
              );
            }
          })}
        </table>
      </div>
    ));
  };

  return <div>{loading ? <RingLoader /> : resourcesToArray(resources)} </div>;
};

export default Resources;
